/* Common styles for larger screens */
.menu-container {
    margin: auto;
    padding: 5px;
    background-color: rgba(44, 61, 73, 0.4);
    text-align: center;
}

.menu-bar {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

.menu-bar li {
    margin: 0 7px;
    position: relative;
}

.menu-bar a {
    text-decoration: none;
    background-color: #0078d4;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 20px;
    display: block;
    width: 90px;
}

.menu-bar a:hover {
    background-color: #018f7e;
}

/* Style the sub-menu */
.sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    /*background-color: #2c3d49;*/
    background-color: rgba(44, 61, 73, 0.6);
    padding: 10px;
    border-radius: 20px;
    display: none;
    z-index: 2;
}

.menu-bar li:hover .sub-menu {
    display: block;
}

.sub-menu li {
    margin: 2px 0;
    list-style: none;
}

.active-link {
    color: #ff0000;
}

/* Media query for smaller screens */
@media only screen and (max-width: 1000px) {
    .menu-bar {
        flex-direction: column; /* Display menu items vertically */
        align-items: flex-start; /* Left-align items vertically */
    }

    .menu-bar li {
        margin: 7px 0; /* Adjust margin for vertical layout */
    }

    .menu-bar a {
        width: 100%; /* Make menu items take full width */
        box-sizing: border-box; /* Include padding and border in the width */
    }

    .sub-menu {
        position: absolute; /* Remove absolute positioning for sub-menu */
        left: 80%;
    }



}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}

@media (min-width: 992px)
.col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
}

.content {
    margin-top: 11%;
}

.type1 {
    color: #ff014f;
    transition: 0.4s;
}
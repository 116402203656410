@import url('https://fonts.googleapis.com/css?family=Tangerine');

header {
    background-color: #12125e;
    color: #fff;
    padding: 1rem;
    text-align: center;
}

/*h1 {*/
/*    margin: 0;*/
/*    !*font-family: 'Tangerine', serif;*!*/
/*}*/

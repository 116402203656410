
th, td {
    text-align: center;
    padding: 25px;
    /*margin: auto;*/
}

fieldset {
    /*text-align: center;*/
    /*width: 90%;*/
    background-color: #e6e8e8;
    margin: auto;
}

legend {
    width: 98%;
    background-color: #363a61;
    color: white;
    padding: 5px 10px;
}

input {
    margin: 5px;
}

.shadowbox {
    width: 15em;
    border: 1px solid #333;
    box-shadow: 8px 8px 5px #444;
    padding: 8px 12px;
    background-image: linear-gradient(180deg, #fff, #ddd 20%, #9b0505);

}


.publications {
    text-align: center;
    padding: 10px;
    margin-bottom: 35px;
    margin-top: 35px;
}

/*.publication img {*/
/*    width: 300px;*/
/*    height: 300px; !* Fixed size for the image *!*/
/*    object-fit: cover; !* Maintain aspect ratio and cover the container *!*/
/*    border: 1px solid #333;*/
/*    box-shadow: 8px 8px 5px #444;*/
/*    background-image: linear-gradient(180deg, #fff, #ddd 20%, #ccc);*/
/*}*/

.publication {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    /*margin: auto;*/
}

/*.publication-image {*/
/*    width: 300px;*/
/*    height: 300px;*/
/*    position: relative;*/
/*    overflow: hidden; !* Ensure the image is clipped to the specified size *!*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.publication-image {
    /* Remove fixed width and height */
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*.publication img {*/
/*    max-width: 100%;*/
/*    max-height: 100%;*/
/*    width: auto;*/
/*    height: auto;*/
/*    border: 1px solid #333;*/
/*    box-shadow: 8px 8px 5px #444;*/
/*}*/

.publication img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    border: 1px solid #333;
    box-shadow: 8px 8px 5px #444;
    object-fit: contain;
}

/*.placeholder {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: #aaa; !* Set a background color for the placeholder *!*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.placeholder-text {*/
/*    font-size: 16px;*/
/*    color: #555;*/
/*}*/

.placeholder {
    width: 100%;
    height: 100%;
    background-color: #e6e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: #282c34;*/
    /*border-width: thick;*/
}

.placeholder-text {
    font-size: 16px;
    color: #555;
}


.publication a {
    font-size: 15px;
    color: #494d78;
    text-decoration: none;
    margin: auto;
}

.publication a:hover {
    text-decoration: underline;
    /*font-size: 22px;*/
}

.publication-title {
    font-size: 23px;
    color: #595e93;
    margin: auto;
}

.publication-author {
    font-size: 18px;
    color: #494d78;
}

.publication-details,
.publication-authors,
.publication-journal {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

/*.publication-image.clicked .shadowbox {*/
/*    box-shadow: none;*/
/*}*/

.background-image {
    width: 100%;
    height: 100%;
}


.fieldset a {
    width: 50%;
    margin: auto;
}

/* Default styles for larger screens */
.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}



/* Media query for smaller screens */
@media (max-width: 600px) {
    .flex-container {
        justify-content: flex-start; /* Align items to the start when the screen is narrower */
    }

    /*.publication {*/
    /*    width: 100%; !* Make each item take the full width *!*/
    /*}*/
}


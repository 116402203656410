@import url('https://fonts.googleapis.com/css?family=Tangerine');

/* Footer.css */
footer {
  background-color: #12125e;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
}

p {
  margin: 0;
}

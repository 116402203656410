@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');

body {
    font-family: 'Merriweather', serif;
    background-color: #f0f0f0; /* Example background color */
    margin: auto;
    padding: 0; /* Remove default padding */
    overflow-x: hidden; /* Prevent horizontal scrolling on smaller screens */
}

.body-container {
    padding-bottom: 70vh;
    margin: 0 5%;
    /*width: 70%;*/
}

/*!* Apply styles to the container div *!*/
/*.page-container {*/
/*    min-height: 2000px; !* Set a minimum content height *!*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/* Style the main content container */
.content-container {
    flex: 1; /* Allow content container to grow and fill available space */
}

h1 {
    margin: auto;
    text-align: center;
    padding: 10px;
    /*margin-bottom: 35px;*/
    /*margin-top: 35px;*/
}

div.body2{
    width: 70%;
    margin: auto;
    /*text-align: center;*/
}

div.body3{
    width: 80%;
    margin: auto;
    /*text-align: center;*/
}
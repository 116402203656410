.flash-card {
    position: relative;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    justify-content: center;
    align-items: center;
}

.flash-card.flipped {
    transform: rotateY(180deg);
}

.flash-card .front,
.flash-card .back {
    /*position: absolute;*/
    width: 100%;
    height: 100%;
    min-width: 400px;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid #036438;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

h3 {
    margin: 0;
    color: #061057;
}

p {
    color: #091670;
}

.flash-card:hover {
    transform: scale(1.02);
}

.flash-card .front {
    background-color: #f2fdf2;
    border: 1px solid #4f529b;
}

.flash-card .back {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    transform: rotateY(180deg);
}
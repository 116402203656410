/*.night-mode-button {*/
/*    position: fixed;*/
/*    top: 10px;*/
/*    right: 10px;*/
/*    background-color: #0078d4;*/
/*    border-radius: 50%;*/
/*    color: #ffffff;*/
/*    border: none;*/
/*    padding: 10px;*/
/*    cursor: pointer;*/
/*    z-index: 1000;*/
/*}*/

/*body.night-mode {*/
/*    background-color: #121212;*/
/*    color: #ffffff;*/
/*}*/

.night-mode-button {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #0078d4;
    color: #ffffff;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
    border-radius: 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.night-mode-button i {
    margin-right: 5px;
}

body.night-mode {
    background-color: #121212;
    color: #ffffff;
}

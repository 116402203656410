/* src/components/NotFound.css */

.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Center content vertically on the screen */
    background-color: #f0f0f0; /* Background color for the entire page */
}

.not-found-content {
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.not-found-content h2 {
    font-size: 2rem;
    color: #ff5722; /* Custom color for the error message */
}

.not-found-content p {
    font-size: 1rem;
    color: #333;
}

.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0078d4;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: none;
    transition: opacity 0.3s;
}

.scroll-to-top-button.visible {
    display: block;
    opacity: 0.7;
}

.scroll-to-top-button i {
    font-size: 20px;
}

.flash-card2 {
    position: relative;
    width: 80%;
    max-width: 1000px;
    /*margin: auto;*/
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    justify-content: center;
    align-items: center;
}

.flash-card2.flipped {
    transform: rotateY(180deg);
}

.flash-card2 .front,
.flash-card2 .back {
    /*position: absolute;*/
    width: 100%;
    height: 100%;
    min-width: 400px;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid #036438;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /*transition: transform 0.01s ease-in-out;*/
}

.flash-card2 h2 {
    margin: 0;
    color: #01062a;
}

.flash-card2 h6 {
    margin: 0;
    color: #01062a;
}

.flash-card2 h5 {
    color: #091670;
}

.flash-card2:hover {
    transform: scale(1.002);
}

.flash-card2 .front {
    background-color: #daefef;
    border: 1px solid #4f529b;
}

.flash-card2 .back {
    background-color: #daefef;
    border: 1px solid #ccc;
    transform: rotateY(180deg);
}
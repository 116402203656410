.professors-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 15px;
}

.professor-card {
    width: 260px;
    margin: 20px;
    padding: 15px;
    border: 1px solid #036438;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.professor-card:hover {
    transform: scale(1.05);
}

.professor-card img {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
}

.card-details {
    margin-top: 10px;
}

h3 {
    margin: 0;
    color: #333;
}

p {
    color: #666;
}

.student-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 35px;
}

.student-card {
    width: 270px;
    margin: 10px;
    padding: 15px;
    border: 1px solid #7eaf01;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.student-card:hover {
    transform: scale(1.05);
}

.student-card img {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
}


.ms-student-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 35px;
}

.ms-student-card {
    width: 250px;
    margin: 20px;
    padding: 15px;
    border: 1px solid #b75200;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.ms-student-card:hover {
    transform: scale(1.05);
}

.ms-student-card img {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
}